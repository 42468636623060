.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.buttons {
  display: flex;
}

.stats_item {
  display: flex;
  align-items: center;
  padding: 7px 16px;
  border-radius: 12px;
  cursor: pointer;
}

.stats_active {
  background: #f5f6fa;
  p {
    color: #2697ff;
  }
  .stats_counter {
    background: #2697ff;
    color: #fff;
  }
}

.stats_item_title {
  color: #999bb4;
  font-weight: 500;
  font-size: 16px;
}

.stats_counter {
  font-size: 10px;
  font-weight: 700;
  margin-left: 8px;
  background: #f5f6fa;
  color: #999bb4;
  padding: 4px 8px;
  border-radius: 16px;
}

.warning_counter {
  background: #ec6b45;
  color: #fff;
}

.total {
  color: #999bb4;
}
