.list {
  overflow-y: auto;
  height: calc(100vh - 155px);
}

.item {
  cursor: pointer;
  padding: 12px 24px;
  border-bottom: 1px solid #e2ebf4;
  &:first-child {
    border-top: 1px solid #e2ebf4;
  }
  &:hover {
    background: #f5f6fa;
  }
}
