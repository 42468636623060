.container {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: #fff;
  display: grid;
  grid-template-columns: 168px 168px 168px;
  padding: 24px 19px 24px 24px;
  border-top: 1px solid #E2EBF4;
}

.button {
  width: 160px;
}

.statusButton {
  color: #FFFFFF;
  width: 160px;
  height: 52px;
}
