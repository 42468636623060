.icon {
    width: 25px;
    height: 27px;
    opacity: 0.5;
    transition: all 0.2s;
    cursor: pointer;
}

.icon:hover {
    opacity: 1;
}

.popup {
    position: absolute;
    top: 32px;
    left: -53px;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    width: 130px;
    height: 26px;
    transition: all 0.2s;
}

.text {
    font-size: 13px;
    line-height: 16px;
    color: #2697FF;
}
