.container {
  margin: 0 24px;
}

.chain {
  position: absolute;
  top: 0;
  left: 6px;
  height: calc(100% - 25px);
  border-left: 2px dashed #C5C8D1;
}

.list {
  margin-top: 16px;
}

.item {
  display: flex;
  margin-bottom: 20px;
  min-height: 35px;
}

.ball {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #e2ebf4;
  border: 2px solid #ffffff;
}

.info {
  margin-left: 16px;
}

.title {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 2px;
  color: #252733;
}

.time {
  font-size: 12px;
  line-height: 14px;
  color: #999bb4;
}

.inactive {
  opacity: 0.3;
}