@import 'shared/styles/Buttons';

.container {
  position: fixed;
  top: 0;
  right: 0;
  width: 560px;
  height: 100%;
  background: #fff;
  z-index: 3;
  overflow-y: auto;
}

.buttons {
  position: sticky;
  background: #ffffff;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 250px 250px;
  top: 100vh;
  right: 0;
  padding: 16px 24px;
  border-top: 1px solid #e2ebf4;
}

.button {
  @include button;
  background: #eef7ff;
  text-align: center;
}

.button_highlighted {
  @include button_highlighted;
  text-align: center;
}
