.icon {
  cursor: pointer;
  margin-left: 10px;
  height: 25px;
}

.list {
  position: fixed;
  top: 70px;
  right: 50px;
  padding: 10px 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 24px #CED6E2;
  border-radius: 12px;
  visibility: hidden;
}

.item {
  font-weight: 600;
  text-transform: uppercase;
  color: #2697ff;
  letter-spacing: 0.8px;
  padding: 10px 16px;
  cursor: pointer;
}