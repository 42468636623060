.container {
  display: grid;
  align-items: center;
  grid-template-columns: 154px 342px;
  padding: 16px;
  border-bottom: 1px solid #e2ebf4;
  &:last-child {
    border: none;
  }
}

.poster {
  width: 138px;
  height: 101px;
  object-fit: cover;
  margin-right: 16px;
}

.title {
  margin-bottom: 8px;
}

.button {
  margin-top: 16px;
  padding: 12px 16px;
}
