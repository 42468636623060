.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 19px;
}

.amount {
  margin-top: 7px;
}
