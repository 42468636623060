.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  background: #fff;
  white-space: nowrap;
  position: absolute;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 50px;
  color: #999bb4;
  top: -50px;
  right: 0;
  z-index: 1;
}
