.container {
  display: grid;
  align-items: center;
  padding: 8px 0 8px 24px;
  cursor: pointer;
  border-bottom: 1px solid #e2ebf4;
  color: #000;

  &:nth-child(odd):not(.active) {
    background: #fbfcfe;
  }
}
