.icon {
  position: absolute;
  cursor: pointer;
  width: 13px;
  height: 13px;
  right: 14px;
  top: 13px;
  border: none;
  background: transparent;
}
