.wrapper {
  display: flex;
  margin-top: 24px;
}


.input {
  width: 300px;
  margin-right: 50px;
}

.tables {
  display: flex;
  flex-direction: column;
  width: 1300px;
  min-height: 740px;
  max-height: 830px;
  overflow-y: auto;
}

.choose {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  height: 40%;
}

.surok {
  justify-self: center;
  align-self: center;
}

.allSection {
  margin-top: 80px;
}
