@import 'shared/styles/Colors';
.title {
  border-bottom: 1px solid $border-color;
  text-transform: uppercase;
  color: $light-text;
  font-weight: 500;
  padding: 12px;
  cursor: pointer;
  &:first-child {
    border-top: 1px solid $border-color;
  }
}

.section {
  margin: 0 24px;
}

.footer {
  position: sticky;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  top: 100vh;
  right: 0;
  padding: 16px 24px;
  border-top: 1px solid #e2ebf4;
  button {
    width: 100%;
    &:first-child {
      margin-right: 16px;
    }
  }
}
