/* для элемента input c type="radio" */

.custom_radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label связанного с .custom_radio */

.custom_radio + label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

/* создание в label псевдоэлемента  before со следующими стилями */

.custom_radio + label::before {
  content: '';
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #e2ebf4;
  border-radius: 50%;
  margin-right: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

/* стили для радиокнопки, находящейся в состоянии checked */

.custom_radio:checked + label::before {
  border: 2px solid #2697ff;
  background-image: url("data:image/svg+xml,<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='6' cy='6' r='6' fill='%232697ff'/></svg>");
}
