.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
}

.icon {
  position: absolute;
  cursor: pointer;
  width: 15px;
  height: 15px;
  top: 15px;
  right: 15px;
  path {
    fill: #FFFFFF;
  }
}

.popup {
  background-color: #ffffff;
  border-radius: 15px;
  width: 400px;
  height: 300px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 20px;
  background-color: rgba(255, 0, 0, 0.5);
  height: 100px;
  border-radius: 15px;
}

.text {
  font-size: 15px;
  padding: 15px;
  text-align: center;
}
