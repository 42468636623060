@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: local('Inter'), url(../../assets/fonts/Inter-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: local('Inter'), url(../../assets/fonts/Inter-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter'), url(../../assets/fonts/Inter-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: local('Inter'), url(../../assets/fonts/Inter-Bold.woff2) format('woff2');
}
