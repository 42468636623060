.container {
  display: flex;
  align-items: center;
  margin-right: 32px;
}

.label {
  color: #999BB4;
  font-size: 14px;
  line-height: 17px;
  margin-right: 16px;
}

.input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #2697ff;
  border-radius: 12px;
  width: 207px;
  height: 36px;
  cursor: pointer;
}

.input {
  border: none;
  border-left: 1px solid #2697ff;
  border-right: 1px solid #2697ff;
  padding: 10px 8px;
  height: 34px;
  font-size: 14px;
  color: #252733;
  width: 141px;
}

.input__arrow {
  width: 32px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    border-right: 1px solid #2697ff;
  }

  &:nth-child(3) {
    border-left: 1px solid #2697ff;
  }
}

.calendar_input_value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  margin: 0 9px;

  & input {
    font-size: 14px;
    border: none;
    width: 87px;
  }
}

.calendar_container {
  position: absolute;
  top: 45px;
  left: 35px;
}

.months {
  box-shadow: 0 8px 42px rgba(0, 0, 0, 0.12);
  border-radius: 14px;
  padding: 20px 25px;
  background: #FFFFFF;
  z-index: 1;
}

.calendar_list {
  background: #ffffff;
  display: grid;
  width: 343px;
  height: 380px;
  z-index: 3;
  box-shadow: 0 0 24px #ced6e2;
  border-radius: 12px;
  margin-top: 8px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 311px;
  z-index: 2;
  background: #ffffff;
  border-top-left-radius: 12px;

  & > span {
    position: absolute;
    right: 0;
    top: 8px;
  }

  span {
    cursor: pointer;
  }
}

.header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow {
  padding: 10px;
}

.month {
  font-weight: 500;
  width: 120px;
  text-align: center;
  text-transform: uppercase;
}

.table {
  max-width: calc(40px * 7);
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
}

.days::before {
  content: '';
  display: block;
  height: 20px;
}

.calendar_day {
  color: #999bb4;
  text-transform: capitalize;
  text-align: center;
}
