.content {
  margin-top: -24px;
  margin-bottom: 24px;
}

.section {
  margin: 20px 24px;
}

.comment {
  flex-direction: column;
  align-items: normal !important;

  p:last-child {
    font-weight: 700;
  }
}

.firstOrder {
  font-weight: 700;
  margin: 24px;
}

.aloha_button {
  background: #ffdb4d;
  margin: 34px 24px 0 24px;
}

.ordersAmount {
  cursor: pointer;
}