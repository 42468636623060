.container {
  display: flex;
  justify-content: flex-end;
}

.icon {
  cursor: pointer;
}

.values {
  font-size: 14px;
}
