.price {
  text-align: center;
}

.dish {
  display: grid;
  align-items: center;
  padding: 12px 0;
}

.additives {
  margin-left: 32px;
  margin-right: 24px;
  margin-bottom: 8px;
}

.additive {
  display: flex;
  justify-content: space-between;
}

.comment {
  font-weight: 700;
}
