.container {
  display: flex;
  align-items: center;
  width: 300px;
}

.input {
  border: none;
  outline: 0;
  width: 100%;
}

.default {
  border-radius: 12px;
  padding: 10px 16px;
  background: #f5f6fa;
}
