.container {
  color: #0A1811;
  text-transform: capitalize;
  text-align: center;
  width: 41px;
  height: 32px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: #EBEDEC;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.1);
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 100%;
}

.today {
  color: #2697FF;
}

.today:before {
  position: absolute;
  left: 5px;
  bottom: 2px;
  width: 75%;
  margin: auto;
  border-bottom: 2px solid #2697FF;
  content: "";
}

.outside {
  color: #6C7470;
}

.selected {
  background: #2697ff;
  color: #fff;
}

.today.selected:before {
  position: absolute;
  left: 5px;
  bottom: 2px;
  width: 75%;
  margin: auto;
  border-bottom: 2px solid #FFFFFF;
  content: "";
}