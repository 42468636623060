.container {
  display: flex;
  border-radius: 100px;
  padding: 7px 0;
  margin: auto 0 auto 24px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  background: #c5c8d1;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
