.container {
  border: none;
  cursor: pointer;
  border-radius: 12px;
  padding: 10px 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.black {
  background: #000000;
  color: #ffffff;
}

.default {
  background: #eef7ff;
  color: #2697ff;
}

.disabled {
  cursor: not-allowed;
  background: #f5f6fa !important;
  color: #999bb4 !important;
}

.green {
  background: transparent;
  border: 1px solid #5CC157;
  color: #5CC157;
}

.grey {
  background: #c5c8d1;
  color: #FFFFFF;
}

.highlighted {
  background: #2697ff;
  color: #ffffff;
}
