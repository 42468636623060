.container {
  margin-top: 16px;
  overflow-x: hidden;
  overflow-y: auto;
}

.titles {
  display: grid;
  padding: 8px 24px;
  border-bottom: 1px solid #e2ebf4;
}

.titleContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.title {
  color: #999bb4;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-right: 2px;
}
