@import '../../styles/Colors';
.input {
  background: $section-color;
  border-radius: 12px;
  width: 235px;
  margin-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;
}

.list {
  position: absolute;
  overflow-y: auto;
  width: 235px;
  max-height: 237px;
  margin-top: 8px;
  background: #ffffff;
  z-index: 2;
  padding: 10px 0;
  box-shadow: 0px 0px 24px #ced6e2;
  border-radius: 12px;
  visibility: hidden;
}

.item {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;
  &:hover {
    background: $section-color;
  }
}
