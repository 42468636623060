@mixin button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  border-radius: 12px;
}

@mixin button_default {
  @include button;
  font-weight: 500;
  line-height: 19px;
  color: #999bb4;
  border: none;
  cursor: pointer;
}

@mixin button_highlighted {
  @include button;
  background: #2697ff;
  padding: 12px 16px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-weight: 600;
}

.button {
  @include button;
  background: #eef7ff;
  padding: 10px 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #2697ff;
  letter-spacing: 0.8px;
}

.button_default {
  @include button;
  padding: 8px 16px;
  color: #999bb4;
}

.button__edit {
  @include button;
  display: flex;
  align-items: center;
  background: #eef7ff;
  margin-left: 24px;
  letter-spacing: 0.8px;
  padding: 10px 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #2697ff;
  p {
    font-weight: 600;
    text-transform: uppercase;
    color: #2697ff;
    letter-spacing: 0.8px;
  }
  span {
    margin-left: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.button__blank {
  @include button;
  padding: 8px 16px;
}

.button__section--active {
  @include button;
  button {
    padding: 8px 16px;
    background: #f5f6fa;
    color: #2697ff;
  }
}

.button__highlighted {
  @include button;
  background: #2697ff;
  padding: 12px 16px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-weight: 600;
}
