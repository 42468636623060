.container {
  overflow-y: auto;
  height: calc(100vh - 80px);
}

.nav_button {
  margin-right: 16px;
}

.table__section {
  margin: 16px 0;
}

.table__title {
  margin: 16px 0;
}

.table__calendars {
  display: flex;
}

.button {
  margin-top: 16px;
}
