.container {
  display: flex;
  align-items: center;
  width: 35px;
  height: 20px;
}

.bg {
  width: 100%;
  height: 12px;
  background: rgba(153, 155, 180, 0.5);
  border-radius: 12px;
  opacity: 0.5;
  transition: background 0.2s;
}

.thumb {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: all 0.2s;
}
