.container {
  position: sticky;
  top: 0;
  background: #3c4b61;
  min-width: fit-content;
  height: 48px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  z-index: 4;
  span {
    font-weight: 600;
    white-space: nowrap;
  }
}

.narrowed {
  width: calc(100% - 560px);
}

.menu {
  display: flex;
}

.logo {
  font-size: 18px;
  font-weight: 600;
  border-right: 1px solid #65768e;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 23px;
}

.menu_item {
  font-size: 14px;
  height: 100%;
  padding: 0 16px;
  border-right: 1px solid #65768e;
  display: flex;
  align-items: center;
}

.menu_item_icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  & path {
    fill: #65768e;
  }
}

.current_tab {
  background: #2697ff;
  path {
    fill: #fff;
  }
}

.icons {
  display: flex;
  justify-content: flex-end;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 48px;
  height: 100%;
  border-left: 1px solid #65768e;
}
