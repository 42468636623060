@import 'shared/styles/Colors';
.container {
  width: 300px;
  height: 740px;
  padding: 24px 0;
  background: $section-color;
  border-radius: 16px;
  margin-right: 50px;
}

.header {
  padding: 0 16px 12px 16px;
  border-bottom: 1px solid $border-color;
}

.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}

.reset {
  color: $active-color;
  cursor: pointer;
}
