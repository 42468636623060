/* для элемента input c type="checkbox" */

.custom_checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label, связанного с .custom_checkbox */

.custom_checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

/* создание в label псевдоэлемента before со следующими стилями */

.custom_checkbox + label::before {
  content: '';
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #e2ebf4;
  border-radius: 4px;
  margin-right: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

/* стили при наведении курсора на checkbox */

.custom_checkbox:not(:disabled):not(:checked) + label:hover::before {
  border: 2px solid #2697ff;
}

/* стили для чекбокса, находящегося в состоянии checked */

.custom_checkbox:checked + label::before {
  border-color: #4d84f7;
  background-color: #4d84f7;
  background-image: url('data:image/svg+xml,\
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.0001 7.78005L1.2201 5.00005L0.273438 5.94005L4.0001 9.66671L12.0001 1.66672L11.0601 0.726715L4.0001 7.78005Z" fill="white"/></svg>');
}

/* стили для чекбокса, находящегося в состоянии disabled */

.custom_checkbox:disabled + label::before {
  background-color: #e9ecef;
}
