@import 'shared/styles/Colors';

.container {
  overflow-y: auto;
  height: 640px;
}

.item {
  display: flex;
  justify-content: space-between;
  margin: 0 16px;
  padding: 12px 0;
  border-bottom: 1px solid $border-color;
  cursor: pointer;
}
