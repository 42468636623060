.table__container {
  margin-top: 25px;
}

.table__titles {
  display: grid;
  padding-bottom: 8px;
  margin-right: 16px;
  margin-top: 24px;
  overflow: hidden;
}

.table__title_container {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:first-child {
    margin-left: 16px;
  }
}

.table__title_text {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #999bb4;
}

.table__item {
  display: grid;
  padding: 8px 0;
  cursor: pointer;
  border-top: 1px solid #e2ebf4;
  color: #000;
  * {
    user-select: text;
  }
  & > p:first-child {
    margin-left: 16px;
  }
  & > div:first-child {
    margin-left: 16px;
  }
  &:nth-child(odd):not(.table_item--active) {
    background: #fbfcfe;
  }
}

.table__item--active {
  background: #e2ebf4;
}
