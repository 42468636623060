.payment_price {
  margin-left: 24px;
}

.payment_price_section {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.payment_price_container {
  display: flex;
  align-items: center;
}

.payment_price_input {
  background: #f5f6fa;
  border-radius: 12px;
  margin-left: 16px;
  padding: 9px 16px;
  border: none;
  width: 119px;
}

.payment_price_currency {
  color: #999bb4;
  position: absolute;
  right: 52px;
}

.payment_method {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}

.payment_button {
  border: 2px solid #e2ebf4;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 12px;
}

.active_button {
  border: 2px solid #2697ff;
}

.active_button_small {
  width: 12px;
  height: 12px;
  margin: 2px;
  background: #2697ff;
  border-radius: 50%;
}
