.dishes_items {
  margin-top: 24px;
}

.dishes_item {
  margin: 0 24px;
  border-bottom: 1px solid #e2ebf4;
  &:first-child {
    border-top: 1px solid #e2ebf4;
  }
}

.dishes_item_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-right: 4px;
  padding: 12px 0;
}

.dishes_details_item {
  display: grid;
  align-items: center;
  grid-template-columns: 176px 1fr;
  margin-bottom: 24px;
}

.dishes_details_remove {
  background: #fdf3f0;
  border-radius: 12px;
  font-size: 13px;
  padding: 10px 8px 10px 12px;
  margin: 34px 0;
  color: #ec6b45;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.8px;
  width: 167px;
  & svg {
    margin-left: 7px;
  }
  & path {
    margin-left: 7px;
    fill: #ec6b45;
  }
}

.dishes_details_select {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.dishes_details_option {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 17px;
}

.dishes_counter {
  display: flex;
  align-items: center;
  margin: 11px 15px;
  border: 1px solid #e2ebf4;
  width: 120px;
  border-radius: 12px;
}

.dishes_counter_value {
  display: flex;
  justify-content: center;
  padding: 11px 0;
  border: none;
  width: 41px;
  text-align: center;
  border-left: 1px solid #e2ebf4;
  border-right: 1px solid #e2ebf4;
}

.dishes_counter_button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 39px;
  height: 38px;
}

.dishes_additionals_item {
  display: flex;
  margin: 16px 0;
}
