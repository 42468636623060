@import 'shared/styles/Colors';
@import 'shared/styles/Buttons';
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
}

.logo {
  background: $active-color;
  width: 100%;
  height: 100px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 25px;
    color: #fff;
    font-weight: 700;
  }
}

.error {
  text-align: center;
  margin: 12px 0;
}

.code_container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  width: 100%;
}

.code_item {
  //background: #f5f6fa;
  width: 50px;
  height: 50px;
  border: none;
  text-align: center;
  border-bottom: 2px solid $border-color;
  &:not(:last-child) {
    //border-right: 1px solid #e2ebf4;
    margin-right: 8px;
  }
  /*&:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    &:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }*/
}

.request_button {
  cursor: pointer;
  text-align: right;
}

.telegram_button {
  @include button;
  padding: 10px 12px;
  margin-top: 12px;
  background: #00008b;
  color: #ffffff;
}

.button_label {
  margin-left: 6px;
  font-size: 13px;
  font-weight: 500;
}
