.container {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 19px 40px 19px;
}

.icons {
  display: flex;
  align-items: center;
}

.icon {
  cursor: pointer;
  margin-left: 10px;
  height: 20px;
}
