.header {
  display: flex;
  justify-content: space-between;
}

.search_input {
  width: 350px;
}

.filter {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.filter_title {
  margin-right: 24px;
}

.button {
  margin-right: 24px;
}

.list {
  overflow-y: auto;
  height: 700px;
}
