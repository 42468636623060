@import '../../styles/Colors';

.container {
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 72px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.icon {
  will-change: transform;
  animation: icon_loading 3s linear infinite;
  width: 60px;

  .icon_front {
    animation: icon_loading_front 1.5s ease-in-out infinite;
    stroke: $active-color;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }

  .icon_back {
    opacity: 0.12;
    stroke: #fff;
    stroke-dasharray: 200, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }
}

@keyframes icon_loading {
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes icon_loading_front {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
