.dish_section {
  margin: 0 24px;
}

.dishes_results {
  box-shadow: 0px 0px 24px #ced6e2;
  border-radius: 12px;
  margin-top: 8px;
}

.dishes_item {
  border-bottom: 1px solid #e2ebf4;
  &:last-child {
    border: none;
  }
}
