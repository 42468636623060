@import 'shared/styles/Buttons';
@import 'shared/styles/Fonts';
//@import 'Order';
@import 'components/Table/Table';

* {
  font-family: 'Inter';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  outline: 0;
  font-style: normal;
  font-weight: 400;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

body {
  background: #ffffff;
  font-size: 14px;
  overflow: hidden;
  color: #252733;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #fff;
  text-decoration: none;
  display: block;
}

h1 {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
}

h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

p {
  line-height: 150%;
}

td {
  padding: 0;
  margin: 0;
}

.page {
  display: flex;
}

.page__main {
  width: 100%;
}

.page_content {
  margin: 24px auto auto auto;
  padding: 0 40px;
  max-width: 1756px;
}

.main_button {
  display: flex;
  align-items: center;
  padding: 7px 16px;
  border-radius: 12px;
  cursor: pointer;
  background: #f5f6fa;
}

.section__head {
  z-index: 2;
  margin-right: 24px;
}

.light_subtitle {
  color: #999bb4;
}

.input {
  background: #f5f6fa;
  border: none;
  padding: 11px 32px 11px 16px;
  margin: 12px 0;
  width: 100%;
  height: 36px;
  border-radius: 12px;
  outline: none;
}

.section_filters {
  display: flex;
  margin: 24px 0;
}

.edit_area {
  position: fixed;
  top: 0;
  right: 0;
  width: 560px;
  height: 100%;
  background: #fff;
  z-index: 3;
  overflow-y: auto;
}

.orders_sorting {
  display: flex;
  align-items: center;

  .light_subtitle {
    margin-right: 24px;
  }
}

.pending {
  background: #ec6b45 !important;
}

.accepted {
  background: #ecb73f !important;
}

.ready {
  background: #2697ff !important;
}

.sent {
  background: #54dea8 !important;
}

.hide {
  display: none;
}

.active_item {
  background: #e2ebf4;
}

.react-datepicker__tab-loop {
  z-index: 1;
}

::-webkit-input-placeholder {
  color: #999bb4;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
