.item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid #e2ebf4;
  &:first-child {
    border-top: 1px solid #e2ebf4;
  }
  &:hover {
    background: #f5f6fa;
  }
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 16px;
  margin-right: 12px;
  background: #c5c8d1;
}
